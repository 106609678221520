import React from 'react';
import './CardLayout.scss';
import { Trans } from 'react-i18next';
import { ArrowBack } from '../../icons/ArrowBack';
import Spinner from './Spinner';

const CardLayout = ({ children, title, buttonText, onClick, disabled, onBack, error, isSubmit, loadingButton }) => {
  return (
    <div className="card">
      <div className="card_wrapper">
        <div className="row">
          <div className="item">
            {onBack && (
              <button className="button_back" onClick={onBack}>
                <ArrowBack />
              </button>
            )}
          </div>
          <div className="item">
            {title && (
              <div className="card_title">
                <Trans i18nKey={title} />
              </div>
            )}
          </div>
        </div>
        <div className="card_content">{children}</div>
        {error && <div className="card_api-error">{error}</div>}
        {onClick && (
          <button
            type={isSubmit ? 'submit' : 'button'}
            className={`card_button ${disabled ? 'disabled' : ''}`}
            onClick={onClick}
          >
            {loadingButton ? <Spinner /> : <Trans i18nKey={buttonText} />}
          </button>
        )}
      </div>
    </div>
  );
};

export default CardLayout;

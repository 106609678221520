import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import './SignatureCard.scss';

const SignatureCard = ({ onSubmit, setDisabledSignButton }) => {
  const canvasRef = useRef(null);
  const [isDraw, setDraw] = useState(false);
  const [isMoved, setMoved] = useState(false);
  const [canvasContext, setCanvasContext] = useState(null);
  const [clickMouseTimes, setClickMouseTimes] = useState(0);
  let x;
  let y;

  useEffect(() => {
    if (canvasRef.current) {
      setCanvasContext(canvasRef.current.getContext('2d'));
    }
  }, [canvasRef]);

  useEffect(() => {
    if (clickMouseTimes === 1) {
      setDisabledSignButton(false);
    }
    onSubmit(canvasRef.current.toDataURL());
  }, [clickMouseTimes]);
  const handleMouseDown = (e) => {
    x = e.clientX - canvasRef.current.getBoundingClientRect().left;
    y = e.clientY - canvasRef.current.getBoundingClientRect().top;
    setDraw(true);
    canvasContext.beginPath();
    canvasContext.moveTo(x, y);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    x = e.touches[0].clientX - canvasRef.current.getBoundingClientRect().left;
    y = e.touches[0].clientY - canvasRef.current.getBoundingClientRect().top;
    setDraw(true);
    canvasContext.beginPath();
    canvasContext.moveTo(x, y);
  };

  const handleMouseMove = (e) => {
    if (isDraw === true) {
      x = e.clientX - canvasRef.current.getBoundingClientRect().left;
      y = e.clientY - canvasRef.current.getBoundingClientRect().top;
      canvasContext.lineTo(x, y);
      canvasContext.stroke();
      setMoved(true);
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (isDraw === true) {
      x = e.touches[0].clientX - canvasRef.current.getBoundingClientRect().left;
      y = e.touches[0].clientY - canvasRef.current.getBoundingClientRect().top;
      canvasContext.lineTo(x, y);
      canvasContext.stroke();
      setMoved(true);
    }
  };

  const handleMouseUp = (e) => {
    x = e.clientX - canvasRef.current.getBoundingClientRect().left;
    y = e.clientY - canvasRef.current.getBoundingClientRect().top;
    canvasContext.lineTo(x, y);
    canvasContext.stroke();
    canvasContext.closePath();
    setDraw(false);
    if (isMoved) {
      setMoved(false);
      setClickMouseTimes((prev) => prev + 1);
    }
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    setDraw(false);
    if (isMoved) {
      setMoved(false);
      setClickMouseTimes((prev) => prev + 1);
    }
  };

  const handleClearCanvas = () => {
    setDisabledSignButton(true);
    setClickMouseTimes(0);
    canvasContext.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  return (
    <>
      <div className="signature_text">
        <Trans i18nKey="guest.signature_text" />
      </div>
      <div className="signature_field">
        <canvas
          id="canvas"
          width={1000}
          height={800}
          ref={canvasRef}
          className="signature_canvas"
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        ></canvas>
        <button className="signature_btn-clear" onClick={handleClearCanvas}>
          <Trans i18nKey="guest.signature_clear_btn" />
        </button>
      </div>
    </>
  );
};

export default SignatureCard;

import React from 'react';
import './WelcomePage.scss';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppStore, ArrowRight, GooglePlay } from '../../icons';

const WelcomePage = ({ welcomeText }) => {
  return (
    <div className="welcome">
      <div>
        <div className="welcome_title">{welcomeText}</div>
      </div>
      <div className="row welcome_buttons">
        <div className="item buttons">
          <NavLink to="/visitor" className="buttons_item">
            <Trans i18nKey="welcome.guest_btn" />
            <ArrowRight />
          </NavLink>
          <NavLink to="/delivery" className="buttons_item">
            <Trans i18nKey="delivery.title" />
            <ArrowRight />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

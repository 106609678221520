import React, { useContext, useEffect, useState } from 'react';
import './styles/reset.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './localization';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MainLayout from './components/pages/MainLayout';
import WelcomePage from './components/pages/WelcomePage';
import VisitorPage from './components/pages/VisitorPage';
import DeliveryPage from './components/pages/DeliveryPage';
import { PATH_APP } from './routes';
import { DeviceAuthGuard, DeviceAuthContext, DeviceConnectRouter } from '@inspace-org/react-auth';
import MainPage from './components/pages/MainPage';

function App() {
  const { settings } = useContext(DeviceAuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleConnection = (isSuccessful) => (device) => {
    if (isSuccessful && device) {
      navigate(PATH_APP.welcome);
    } else {
      navigate(PATH_APP.root);
    }
  };

  return (
    <div className="App">
      <MainLayout>
        <TransitionGroup style={{ height: '100%' }}>
          <CSSTransition key={location.key} classNames="right-to-left" timeout={1000}>
            <>
              <DeviceConnectRouter onSuccess={handleConnection(true)} onFailure={handleConnection(false)} />
              <Routes>
                <Route path={PATH_APP.root} element={<MainPage />} />
                <Route
                  path={PATH_APP.welcome}
                  element={
                    <DeviceAuthGuard redirectTo={PATH_APP.root}>
                      <WelcomePage welcomeText={settings?.welcomeText?.text} />
                    </DeviceAuthGuard>
                  }
                />
                <Route
                  path={PATH_APP.visitor}
                  element={
                    <DeviceAuthGuard redirectTo={PATH_APP.root}>
                      <VisitorPage settingsWifi={settings?.wifi} />
                    </DeviceAuthGuard>
                  }
                />
                <Route
                  path={PATH_APP.delivery}
                  element={
                    <DeviceAuthGuard redirectTo={PATH_APP.root}>
                      <DeliveryPage />
                    </DeviceAuthGuard>
                  }
                />
              </Routes>
            </>
          </CSSTransition>
        </TransitionGroup>
      </MainLayout>
    </div>
  );
}

export default App;

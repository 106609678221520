export const normalizeApiUrl = (url = '') => {
  if (url === '.' || url === '/' || !url) {
    return '';
  }
  return new URL(url).origin;
};

export function makeOffsetTimeZoneString() {
  let off = new Date().getTimezoneOffset();
  let tz = window.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';
  return `${off},${tz}`;
}

export const debounce = (func, delay) => {
  let timeout = null;
  return (...rest) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...rest);
        timeout = null;
      }, delay);
    } else
      timeout = setTimeout(() => {
        func(...rest);
        timeout = null;
      }, delay);
  };
};

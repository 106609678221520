import React, { useState } from 'react';
import './SearchUsers.scss';
import Spinner from './Spinner';
import { UsersClient } from '../../services/http/apis/usersClient';
import { debounce } from '../../services/utils';

const SearchUsers = ({ value = '', onBlur, onChange, setValueUser, helperText, error, placeholder, hostsList }) => {
  const [userList, setUsersList] = useState(hostsList || []);
  const [isLoadingUser, setLoadingUser] = useState(false);
  const usersClient = new UsersClient();

  const loadUsers = async (value) => {
    if (value !== '') {
      try {
        setLoadingUser(true);
        const { users } = await usersClient.getUsers({ search: value });
        setUsersList(users);
      } catch (error) {
        setUsersList([]);
        console.error(error);
      } finally {
        setLoadingUser(false);
      }
    } else {
      setUsersList([]);
    }
  };

  const handelChangeInput = (e) => {
    const val = e.target.value;
    getDebouncedUsers(val);
    setValueUser(val);
  };

  const getUserDisplayName = (user, type) => {
    if (user) {
      if (user.firstName && user.lastName) {
        return type === 'avatar'
          ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
          : `${user.firstName} ${user.lastName}`;
      } else if (user.lastName) {
        return type === 'avatar' ? user.lastName[0].toUpperCase() : user.lastName;
      } else if (user.firstName) {
        return type === 'avatar' ? user.firstName[0].toUpperCase() : user.firstName;
      }
      return type === 'avatar' ? user.email[0].toUpperCase() : user.email;
    }
  };

  const getDebouncedUsers = debounce(loadUsers, 100);

  const handleSelectUser = (user) => {
    setValueUser(getUserDisplayName(user));
    onChange(user._id || user.id);
  };

  return (
    <div className="form_field">
      <input
        className={`form_input ${error ? 'error' : ''}`}
        name="hosts"
        placeholder={placeholder}
        value={value}
        onChange={handelChangeInput}
        onBlur={onBlur}
      />
      {error && <div className="error_text">{helperText}</div>}
      {isLoadingUser ? (
        <Spinner />
      ) : (
        <div className="form_users">
          {userList.map((user) => (
            <div key={user._id || user.id} className="user" onClick={() => handleSelectUser(user)}>
              <div className="user_avatar">
                <div className="avatar">{getUserDisplayName(user, 'avatar').toUpperCase()}</div>
              </div>
              <div className="user_name">{getUserDisplayName(user)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchUsers;
